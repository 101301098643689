
import { defineComponent } from "vue";

export default defineComponent({
  name: "dropdown-2",
  components: {},
  props:{
    itemID: String,
  },
});
