
import {computed, defineComponent, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {ErrorMessage, Field, Form} from "vee-validate";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import MasterConfig from "@/core/config/MasterConfig";
import {dateConvert} from "@/core/filters/datatime";
import {commonf} from "@/core/filters/common";
import Tagify from '@yaireo/tagify';
import FileList from "@/components/common/FileList.vue";
import Dropdown2 from "@/components/reservations/components/ProductsMenu.vue";

interface updCur {
  resID: string;
  curTRY: string;
  curUSD: string;
  curEUR: string;
  curSTG: string,
}

export default defineComponent({
  name: "account-settings",
  data: function () {
    return {}
  },
  components: {
    ErrorMessage,
    Field,
    Form,
    FileList,
    Dropdown2,
  },
  setup() {
    const searchName = ref("");
    const route = useRoute();
    const id = route.params.id;

    const updCur = ref<updCur>({
      resID: "",
      curTRY: "",
      curUSD: "",
      curEUR: "",
      curSTG: "",
    });

    const payload = {
      ID: id
    }
    store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, payload);
    const myDetails = computed(() => {
      let tmpArr = store.getters.reservationsDetails
      if (tmpArr !== undefined) setCur(tmpArr);
      return tmpArr;
    });
    const setCur = (DATA) => {
      if (DATA.CurRate !== undefined) {
        //console.log(typeof DATA.CurRate)
        let tmpArray = JSON.parse(DATA.CurRate);
        updCur.value.curTRY = tmpArray['TRY'];
        updCur.value.curUSD = tmpArray['USD'];
        updCur.value.curEUR = tmpArray['EUR'];
        updCur.value.curSTG = tmpArray['STG'];
        updCur.value.resID = DATA.ID;
        return true;
      } else {
        return false;
      }

    }

    const payload3 = {
      ReservationID: id,
      //ProductType: 'FLIGHT'
      is_active: "active",
    }
    store.dispatch(Actions.RESERVATION_PRODUCTS_LIST, payload3);
    const myList = computed(() => {
      return store.getters.reservationProductsList;
    });


    const filterFieldName = ['İsim', 'Soyisim', 'Pax Türü', 'Nereden', 'Nereye', 'Uçuş Numarası', 'PNR'];
    const myFilteredList = computed(() => {
      if (myList.value.data !== undefined) {
        console.log(myList.value.data);
        return (myList.value.data).filter(itemFil =>
            (itemFil.PaxName !== undefined && !(itemFil.PaxName).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.PaxSurname !== undefined && !(itemFil.PaxSurname).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.PaxType !== undefined && !(itemFil.PaxType).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.From !== undefined && !(itemFil.From).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.To !== undefined && !(itemFil.To).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.RemarksVC1 !== undefined && !(itemFil.RemarksVC1).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.RemarksVC4 !== undefined && !(itemFil.RemarksVC4).toLowerCase().indexOf(searchName.value.toLowerCase()))
        );
      } else {
        return [];
      }
    });


    const payload_2 = {
      Type: 'RESERVATION'
    }
    store.dispatch(Actions.TAGS_LIST, payload_2);
    const myTags = computed(() => {
      return store.getters.tagsList;
    });
    const myTagsList1 = computed(() => {
      if (myTags.value.data) {
        let tmpList = [];
        for (let i = 0; i < myTags.value.data.length; i++) {
          tmpList[i] = {
            value: myTags.value.data[i].Title,
            title: myTags.value.data[i].Title,
            ID: myTags.value.data[i].ID,
          };

        }
        setTagInput(tmpList);
        return tmpList;

      } else {
        return [];
      }
    });

    const payload_3 = {
      ReservationID: id
    }
    store.dispatch(Actions.RESERVATION_TAGS_LIST, payload_3);
    const myTagValues = computed(() => {
      let tmpArray = store.getters.reservationTagsList;
      console.log("TAG LIST:: " + tmpArray);
      //if(tmpArray.data !== undefined)myTagsSelected(tmpArray);
      return tmpArray;
    });

    const myTagsSelected = (tList) => {
      console.log("TYPE: " + typeof tList)
      if (myTagValues.value !==undefined) {
        let tmpList = [];
        for (let i = 0; i < myTagValues.value.data.length; i++) {
          tmpList[i] = {
            value: myTagValues.value.data[i].Title,
            title: myTagValues.value.data[i].Title,
            ID: myTagValues.value.data[i].ID,
          };

        }
        tagify.addTags(tmpList);
        var input = document.querySelector('input[name=myTagsInput]');
        input.addEventListener("change", onChange, false);

        return tmpList;
      } else {
        return [];
      }
    };


    let tagify;
    const setTagInput = (wList) => {
      var input = document.querySelector('input[name=myTagsInput]');
// init Tagify script on the above inputs
      tagify = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        skipInvalid: true, // do not remporarily add invalid tags
        transformTag: transformTag,
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 5,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        }
      });

    }

    function getRandomColor() {
      function rand(min, max) {
        return min + Math.random() * (max - min);
      }

      var h = rand(1, 360) | 0,
          s = rand(40, 70) | 0,
          l = rand(65, 72) | 0;
      return 'hsl(' + h + ',' + s + '%,' + l + '%)';
    }

    function transformTag(tagData) {
      tagData.color = getRandomColor();
      //tagData.style = "--tag-bg:" + tagData.color;
      tagData.style = " --tag-text-color:" + tagData.color;

      if (tagData.value.toLowerCase() == 'shit')
        tagData.value = 's✲✲t'
    }

    function onChange(e) {
      // outputs a String
      console.log(JSON.parse(e.target.value));
      let tmpObj = JSON.parse(e.target.value);
      let payload = {
        editItem: {
          ReservationID: id
        },
        data: []
      };

      for (let i = 0; i < tmpObj.length; i++) {
        payload.data[i] = {
          ReservationID: id,
          TagID: tmpObj[i].ID,
        };
      }
      store.dispatch(Actions.RESERVATION_TAGS_EDIT_LIST, payload);
    }

    /***************************************************************************/

    store.dispatch(Actions.HOTEL_DETAILS, payload);
    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });


    let today = new Date().toLocaleDateString()
    today = (dateConvert.method.convertToDateYM(today));

    const submitButton1 = ref<HTMLElement | null>(null);
    const submitButton2 = ref<HTMLElement | null>(null);
    const submitButton3 = ref<HTMLElement | null>(null);
    const submitButton4 = ref<HTMLElement | null>(null);
    const deactivateHotelButton = ref<HTMLElement | null>(null);
    const hotelSale = ref(null)
    const hotelActive = ref(null)


    let isActive;
    let isSale;

    let isHotelSale = computed(() => {
      return (myDetails2.value.is_sale == 'open') ? true : false;
    });

    let isHotelActive = computed(() => {
      return (myDetails2.value.is_active == 'active') ? true : false;
    });

    const profileDetailsValidator = Yup.object().shape({
      hotelTitle: Yup.string().required().label("İsim"),
      hotelCity: Yup.string().required().label("Şehir"),
      hotelEmail: Yup.string().required().email().label("Email"),
      hotelWeb: Yup.string().required().label("Web sitesi"),
      hotelPhone: Yup.string().required().label("Telefon"),
      hotelFax: Yup.string().required().label("Fax"),
    });


    const deactiveHotel = Yup.object().shape({
      deactivate: Yup.string().required().label("Hesap Silme Onayı"),
    });


    const saveChanges1 = () => {

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        let myAccounts = document.getElementById("updAccounts") as HTMLInputElement;
        let myAccountDetails = filterAccounts(myAccounts.value);
        const payload1 = {
          ID: myDetails.value.ID,
          AccountVoucherNo: myDetails.value.AccountVoucherNo,
          Department: myDetails.value.Department,
          AccountID: (myAccountDetails) ? myAccountDetails[0]['ID'] : myDetails.value.AccountID
        }
        console.log(payload1);

        store.dispatch(Actions.RESERVATIONS_EDIT, payload1)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButton1.value?.removeAttribute("data-kt-indicator");
            }).catch(() => {
          Swal.fire({
            text: store.getters.getReservationsErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Tekrar dene!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          submitButton1.value?.removeAttribute("data-kt-indicator");
        });
      }
    };

    const saveChanges2 = () => {
      if (submitButton2.value) {
        // Activate indicator
        submitButton2.value.setAttribute("data-kt-indicator", "on");

        const payload2 = {
          ID: myDetails2.value.ID,
          ReservationEmail: myDetails2.value.ReservationEmail,
          ReservationPhone: myDetails2.value.ReservationPhone,
          SaleEmail: myDetails2.value.SaleEmail,
          SalePhone: myDetails2.value.SalePhone,
          OperationEmail: myDetails2.value.OperationEmail,
          OperationPhone: myDetails2.value.OperationPhone,
        }

        store.dispatch(Actions.HOTEL_EDIT, payload2)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButton2.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton2.value?.removeAttribute("data-kt-indicator");
            });

      }
    };

    const saveChanges3 = () => {
      if (submitButton3.value) {
        submitButton3.value.setAttribute("data-kt-indicator", "on");
        const curRate = {
          TRY: updCur.value.curTRY,
          USD: updCur.value.curUSD,
          EUR: updCur.value.curEUR,
          STG: updCur.value.curSTG,
        };
        const payload3 = {
          ID: updCur.value.resID,
          CurRate: JSON.stringify(curRate),
        }
        console.log(payload3);
        store.dispatch(Actions.RESERVATIONS_EDIT, payload3)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButton3.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getReservationsErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton3.value?.removeAttribute("data-kt-indicator");
            });

      }
    };

    const saveChanges4 = () => {
      if (submitButton4.value) {
        // Activate indicator
        submitButton4.value.setAttribute("data-kt-indicator", "on");
        isActive = (hotelActive.value.checked) ? 'active' : 'passive';
        isSale = (hotelSale.value.checked) ? 'open' : 'stop';

        const payload2 = {
          ID: myDetails2.value.ID,
          is_active: isActive,
          is_sale: isSale,
        }

        store.dispatch(Actions.HOTEL_EDIT, payload2)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButton4.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton4.value?.removeAttribute("data-kt-indicator");
            });

      }
    };

    const deactivateHotel = () => {
      if (deactivateHotelButton.value) {
        // Activate indicator
        deactivateHotelButton.value.setAttribute("data-kt-indicator", "on");

        const payload6 = {
          ID: myDetails2.value.ID,
        }
        console.log(payload6);
        store.dispatch(Actions.HOTEL_DELETE, payload6)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde silme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully delete
                //router.push({name: "userlist"});
                //this.$router.push({name: "hotellist"});
              });
              deactivateHotelButton.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              deactivateHotelButton.value?.removeAttribute("data-kt-indicator");
            });
      }
    };


    onMounted(() => {
      setCurrentPageBreadcrumbs("Özellikler", ["Oteller"]);
    });


    store.dispatch(Actions.REGIONS_LIST, {});
    const myRegionsList = computed(() => {
      return store.getters.RegionsList;
    });

    const filteredParentRegions = computed(() => {
      if (myRegionsList.value.data != null) {
        return (myRegionsList.value.data).filter(parentRegionsItem =>
            (parentRegionsItem.Country).toLowerCase() == (myDetails2.value.Country).toLowerCase() &&
            (parentRegionsItem.ParentID == "null" || parentRegionsItem.ParentID == 0)
        );
      } else {
        return true;
      }
    });

    const filteredRegions = computed(() => {
      if (myRegionsList.value.data != null) {
        return (myRegionsList.value.data).filter(regionsItem =>
            regionsItem.ParentID == myDetails2.value.ParentRegionID
        );
      } else {
        return true;
      }
    });
    const filteredChildRegions = computed(() => {
      if (myRegionsList.value.data != null) {
        return (myRegionsList.value.data).filter(childRegionsItem =>
            childRegionsItem.ParentID == myDetails2.value.RegionID
        );
      } else {
        return true;
      }
    });


    store.dispatch(Actions.CITY_LIST, {});
    const myCities = computed(() => {
      return store.getters.CityList;
    });
    const filteredCity = computed(() => {
      if (myCities.value.data != null) {
        return (myCities.value.data).filter(citiesItem =>
            citiesItem.Country == (myDetails2.value.Country).toLowerCase()
        );
      } else {
        return true;
      }
    });

    store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
    const myDepartments = computed(() => {
      return store.getters.DepartmentsList;
    });

    const myAccountList = computed(() => {
      return store.getters.accountList;
    });
    const myAccountsTitle = computed(() => {
      let tmpArray = [];
      if (myAccountList.value.data !== undefined) {
        for (var i of myAccountList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });

    const getAccounts = () => {
      let myAccounts = document.getElementById("updAccounts") as HTMLInputElement;
      let myPayload = {};
      console.log(myAccounts);
      if (myAccounts.value.length == 3) {
        myPayload = {
          'Title': myAccounts.value
        }
        store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
      }
    }
    const filterAccounts = (val) => {
      if (myAccountList.value.data != null) {
        return (myAccountList.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return false;
      }
    };

    return {
      id,
      myDetails,
      getAccounts,
      myAccountsTitle,
      myDepartments,
      dateConvert,
      commonf,
      filterFieldName,
      myFilteredList,
      searchName,
      updCur,
      onChange,
      myTagsList1,
      myTags,
      myTagsSelected,
      myTagValues,

      myDetails2,
      isHotelSale,
      isHotelActive,
      myCities,
      myRegionsList,
      filteredCity,
      filteredParentRegions,
      filteredRegions,
      filteredChildRegions,
      MasterConfig,
      hotelActive,
      hotelSale,
      submitButton1,
      submitButton2,
      submitButton3,
      submitButton4,
      saveChanges1,
      saveChanges2,
      saveChanges3,
      saveChanges4,
      deactivateHotel,
      profileDetailsValidator,
      deactiveHotel,
      deactivateHotelButton,
    };
  },
});
